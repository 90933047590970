.main {
  .swiper-container .swiper-slide {
    @include media("<=768px") {
      //height: 100vh;
    }
  }
  svg {
    width: 100%;
    height: 100%;
    @include media("<=980px") {
      display: block;
      margin: 0 auto;
      width: 80%;
    }

    [data-name="lines"],
    [data-name="hover"] {
      opacity: 0;
      transition: 0.3s all ease-in-out;
    }
    [data-name="ropes"],
    [data-name="full"],
    [data-name="paspul"],
    [data-name="label"],
    [data-name="back"],
    [data-name="front"] {
      cursor: pointer;
    }
    [data-name="ropes"]:hover [data-name="lines"],
    [data-name="full"]:hover [data-name="lines"],
    [data-name="paspul"]:hover [data-name="lines"],
    [data-name="label"]:hover [data-name="lines"],
    [data-name="back"]:hover [data-name="lines"],
    [data-name="front"]:hover [data-name="lines"] {
      opacity: 1;
    }
    [data-name="ropes"]:hover [data-name="hover"],
    [data-name="full"]:hover [data-name="hover"],
    [data-name="paspul"]:hover [data-name="hover"],
    [data-name="label"]:hover [data-name="hover"],
    [data-name="back"]:hover [data-name="hover"],
    [data-name="front"]:hover [data-name="hover"] {
      opacity: 1;
    }
  }
}
