@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import "./variables.scss";
@import "./breakpoints.scss";
@import "./components/header.scss";
@import "./components/navigation.scss";
@import "./pages/home.scss";
@import "./pages/contact.scss";
@import "./pages/models.scss";
@import "../styles/pages/gallery.scss";

html,
body {
  visibility: hidden;
  user-select: none;
  background: $white;
  overscroll-behavior: none;
  margin: 0;
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 400;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 0 auto;
}

h2 {
  display: block;
  position: absolute;
  width: 100%;
  top: 60px;
  font-weight: 400;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  @include media("<=phone") {
    top: 120px;
  }
}

.row {
  display: flex;
  height: 100%;
  padding: 0 32px;
  @include media("<=phone") {
    padding: 0 30px;
  }
}
.v-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}

.App {
  z-index: 2;
  transform: translateY(0);
  position: relative;
}

.white .logo a svg {
  transition: all 0.6s ease-in-out;
  fill: white;
}
.white .header .nav-toggle .hamburger-menu-close svg line,
.white .header .nav-toggle .hamburger-menu-close svg path,
.white .header .nav-toggle .hamburger-menu-close svg #circle {
  stroke: #fff;
}
.white .header .nav-toggle .hamburger-menu span {
  background: white;
}
@media only screen and (max-width: 1350px) {
  .white .logo a svg {
    fill: black;
  }
  .white .header .nav-toggle .hamburger-menu-close svg line,
  .white .header .nav-toggle .hamburger-menu-close svg path,
  .white .header .nav-toggle .hamburger-menu-close svg #circle {
    stroke: black;
  }
  .white .header .nav-toggle .hamburger-menu span {
    background: black;
  }
}
