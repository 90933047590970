.main {
  width: 100%;
  height: 100vh;
  background: $white;
  padding: 0;
  box-sizing: border-box;
  display: flex;

  .container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s ease-in-out;
    visibility: hidden;
  }
}
.video-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: #fff;
  }
  .video-overlay-click {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
.video-bar {
  display: none !important;
}
