.swiper-container {
  padding: 150px 0 !important;
  opacity: 0;
  transform: translateX(-30px);

  @include media("<=980px") {
    width: 80%;
  }
  .swiper-slide {
    width: 300px;
    //height: 100%;
    @include media("<=768px") {
      //height: 50vh;
    }
    .slide-number {
      position: absolute;
      top: -35px;
      left: 0;
      z-index: 1;
      width: 300px;
      height: 50px;
      overflow: hidden;

      p {
        padding: 0;
        margin: 0;
        font-size: 21px;
        position: relative;

        span {
          position: absolute;
        }
      }
    }
    .slide-img {
      background-position: 50%;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
  .swiper-navigation-holder {
    display: table;
    overflow: hidden;
    width: 90px;
    height: 30px;
    position: relative;
    margin: 0 auto;
    bottom: -45px;

    .swiper-button-prev {
      top: auto;
      bottom: 0;
      left: 0;
      right: auto;
      width: 30px;
      height: 30px;
      &:after {
        font-size: 30px;
        color: $black;
        font-weight: 600;
      }
    }
    .swiper-button-next {
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
      width: 30px;
      height: 30px;
      &:after {
        font-size: 30px;
        color: $black;
        font-weight: 600;
      }
    }
  }
}
