.header {
  position: fixed;
  width: 100%;
  min-width: 100%;
  height: 90px;
  z-index: 4;

  .logo {
    svg {
      @include media("<=tablet") {
        width: 45px;
      }
      width: 55px;
      transition: all 0.6s ease-in-out;
    }
  }
  .nav-toggle {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media("<=tablet") {
      margin-right: 24px;
    }
    @include media("<=phone") {
      width: 20px;
      margin-right: 16px;
    }

    .hamburger-menu {
      width: 25px;
      span {
        text-align: center;
        margin-bottom: 5px;
        background: $black;
        height: 2px;
        width: 100%;
        display: block;
      }
    }
    .hamburger-menu-close {
      position: absolute;
      svg {
        display: none;
        width: 45px;
      }
    }
  }
}
