.container-in {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 150px);
  opacity: 0;
  p {
    padding: 15px 0;
    font-size: 1.2rem;
    @include media("<=phone") {
      margin: 0;
    }
  }
  ul {
    display: flex;
    margin: 0 auto;
    padding: 0;
    li {
      list-style-type: none;
      padding: 0 30px;
      margin: 0;
      display: flex;
      align-items: center;
      @include media("<=phone") {
        padding: 10px;
      }
      svg {
        width: 21px;
        height: 21px;
        margin-right: 10px;
        transition: 0.4s ease;
        @include media("<=phone") {
          margin-right: 0;
        }
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 0.4rem;
        text-decoration: none;
        font-weight: 400;
        color: $black;
        transition: 0.4s ease;
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 2px;
          display: block;
          right: 0;
          bottom: -10px;
          background: $yellow;
          transition: 0.4s ease;
        }
      }
      span {
        @include media("<=phone") {
          display: none;
        }
      }
      &:hover {
        a {
          color: $yellow;
          &:after {
            width: 100%;
            left: 0;
          }
        }
        svg {
          fill: $yellow;
        }
      }
    }
  }
}
