nav {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: fixed;
  overflow: hidden;
  display: none;

  .menu-backgrounds {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .nav-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green;

    .nav-links {
      padding: 0;
      margin: 0;
      z-index: 1;
      li {
        font-weight: 300;
        list-style: none;
        font-size: 2rem;
        margin-bottom: 1.2rem;
        text-align: center;
        text-transform: uppercase;
        a {
          text-decoration: none;
          color: $black;
          position: relative;
          transition: 0.4s ease;
          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 2px;
            display: block;
            margin-top: 5px;
            right: 0;
            background: $white;
            transition: 0.4s ease;
          }
          &:hover {
            color: white;
            &:after {
              width: 100%;
              left: 0;
            }
          }
        }
      }
    }
  }
}
